import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';
import { Content } from '../../../layout/AdminToolContent/styles';

const appearX = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearZ = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-100px);
  }
  to {
    opacity: 1;
    transform: translateZ(0px);
  }
`;

export const GoBack = styled.div`
  display: flex;
  animation: ${appearX} 0.6s;
  margin-top: -10px 0 10px 0;

  & a {
    display: flex;
    align-items: center;
    color: #888888;

    & svg {
      font-size: 1.5em;
    }

    & p {
      font-size: 1em;
      font-weight: 500;
    }
  }
`;

export const Container = styled.div`
  padding-bottom: 2rem;
  overflow: hidden;

  & div.separator {
    background-color: ${shade(0, '#1B9EB2')};
    width: 10px;
    height: 100%;
    border-radius: 10px;

    @media (min-width: 1536px) {
      height: 100%;
      min-height: calc(80vh - 150px);
    }

    @media (min-width: 768px) {
      margin: 1rem;
    }
  }

  @media (max-width: 1385px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    & div.separator {
      width: 100%;
      height: 10px;
    }
  }

  @media (max-width: 768px) {
    gap: 10px;
    overflow: auto;
  }

  @media (min-width: 1385px) {
    display: flex;
    justify-content: space-around;
  }
`;

export const InfoDevice = styled(Content)`
  margin-top: 20px;
  width: 650px;
  animation: ${appearX} 0.6s;
  padding: 0;
  flex-direction: column;
  height: 100%;

  & div.device {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    margin: 0 0 50px 0;

    & span {
      min-width: 45%;

      & h2 {
        color: #0c5c75;
        font-weight: 600;
        font-size: 1.3em;
      }

      & p {
        color: #000000;
        font-weight: 500;
        font-size: 1em;
        margin-top: 5px;
      }
    }
  }

  & > div.results {
    gap: 10px;
    width: 100%;
    margin: 0;
    overflow: auto;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      max-height: 300px;
    }

    & > h3 {
      font-size: 1.3em;
      font-weight: 600;
      color: #0c5c75;
      margin-bottom: 1rem;
    }
    & ul {
      width: 100%;
      overflow: auto;
      max-height: 420px;
      padding-left: 5px;
      & > h3 {
        color: #1b9eb2;
        font-size: 1em;
      }
      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ececec;
        border-radius: 10px;
        width: 95%;
        height: 125px;
        padding: 5px 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        gap: 20px;
        box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};

        & p {
          font-weight: 600;
          width: 100%;
          text-align: center;
        }

        & p.date {
          text-align: start;
          font-weight: 500;
        }

        &:hover {
          font-weight: 300;
          cursor: default;
        }
      }

      ::-webkit-scrollbar {
        width: 15px;
      }
      ::-webkit-scrollbar-track {
        background: #ececec;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${shade(0.3, '#ececec')};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InfoSensors = styled(Content)`
  margin-top: 20px;
  width: 650px; /* Aumentar a largura para 85% da tela */
  animation: ${appearX} 0.6s;
  padding: 0;

  & > div.results {
    padding-bottom: 50px;
    gap: 10px;
    width: 100%;
    margin: 0;
    overflow: auto;

    @media (min-width: 1536px) {
      overflow: visible;
    }

    & > div.sensorsLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 70px;
        height: 70px;
        background-color: #1b9eb2;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        /* z-index: 1; */

        & svg {
          font-size: 30px;
          color: #ffffff;
        }

        @media (max-width: 768px) {
          scale: 0.7;
        }
      }
    }

    & h3 {
      font-size: 1.3em;
      font-weight: 600;
      color: #0c5c75;
    }
    & ul {
      max-height: 350px;
      overflow-y: auto;
      padding-right: 10px;

      @media (min-width: 1536px) {
        max-height: none;
        overflow-y: visible;
      }

      & div.card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #ececec;
        border-radius: 10px;
        width: 100%;
        height: 100px;
        padding: 15px 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};

        /* &:last-child {
          padding-bottom: 2rem;
        } */

        & div.infos {
          width: 60%;
          display: flex;
          flex-wrap: wrap;

          & span.sensor {
            width: 60%;

            & h2 {
              font-size: 0.9em;
              font-weight: 400;
              color: #0c5c75;
            }

            & p {
              font-size: 0.9em;
              font-weight: 400;
              margin-top: 5px;
            }
          }
          & span.port {
            width: 40%;

            text-align: center;

            & h2 {
              font-size: 0.9rem;
              font-weight: 400;
              color: #0c5c75;
            }

            & p {
              font-size: 0.9rem;
              font-weight: 400;
              width: 100%;
              margin-top: 5px;
            }
          }
          & span.unit {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;

            & h2 {
              font-size: 0.9em;
              font-weight: 600;
              color: #0c5c75;
              white-space: nowrap;
            }

            & p {
              font-weight: 400;
              width: 100%;
              font-size: 0.9em;
              /* text-align: center; */
            }
          }
        }

        & div.edit {
          position: relative;
          width: 15%;
          display: flex;
          align-items: end;
          justify-content: center;
          gap: 20px;

          & span {
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
            height: 10px;
            border-radius: 10px;
          }

          & span.active {
            background-color: #76ee59;
          }
          & span.desative {
            background-color: #ff0000;
          }

          & a.edit {
            width: 30px;
            height: 30px;
            & svg {
              font-size: 30px;
              color: #000000;
            }
          }

          & button.trash {
            width: 30px;
            height: 30px;
            padding: 0;
            & svg {
              font-size: 30px;
              color: #ff0000;
            }
          }

          @media (max-width: 768px) {
            flex-direction: column;
            justify-content: end;
            gap: 5px;
            margin-right: -15px;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 15px;
      }
      ::-webkit-scrollbar-track {
        background: #ececec;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${shade(0.3, '#ececec')};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 40px 0 0 0;
    & > div.results {
      overflow: visible;
    }
  }
`;

export const Span = styled.span<{ height?: number }>`
  display: flex;
  height: ${({ height }) => height}rem;

  transition: 1s;
  overflow: hidden;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
`;

export const DeletePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearZ} 0.7s;
  z-index: 9999;

  & div {
    background-color: #ffffff;
    opacity: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    border-radius: 20px;
    gap: 50px;

    & svg {
      color: #ff0000;
      scale: 3;
    }

    & p {
      font-size: 18px;
      font-weight: 800;
      color: #000000;
    }

    & div.buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 50px;
      width: 100%;
      height: auto;

      & button.delete {
        width: 35%;
        background-color: #ff0000;
      }
      & button.cancel {
        width: 35%;
        background-color: transparent;
        border: 2px solid #ff0000;
        color: #ff0000;
      }
    }
    @media (max-width: 768px) {
      width: 350px;
      height: 400px;
    }
  }
`;

export const NewSensorPopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearZ} 0.7s;
  z-index: 999;

  & form.form {
    background-color: #f6f6f6;
    opacity: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 300px;
    border-radius: 20px;
    gap: 50px;
    padding: 0 20px;
    position: relative;

    & button.close {
      background-color: transparent;
      width: 24px;
      height: 24px;
      color: #1b9eb2;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;

      & svg {
        font-size: 24px;
      }
    }

    & p {
      color: #0c5c75;
      font-weight: 600;
      font-size: 1.3em;
      margin-bottom: 10px;
    }

    & div.inputs {
      width: 95%;
      gap: 10px;

      & div.s2 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        gap: 50px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    & div.butts {
      width: 100%;
      display: flex;
      justify-content: space-around;

      & div {
        display: flex;
        gap: 20px;
      }

      & button {
        width: 210px;
        height: 30px;
        border-radius: 30px;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: #1b9eb2;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    @media (max-width: 768px) {
      width: 300px;
      height: 500px;
    }
  }
`;

export const BatteryLevelLabelContainer = styled.span`
  display: flex;
  flex-direction: column;

  max-width: 100%;
  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 12px;
    color: #0c5c75;
    display: flex;
    align-items: center;
  }
`;

export const BatteryLevelLabel = styled.p<{ lowLevel?: boolean }>`
  ${({ lowLevel }) =>
    lowLevel &&
    css`
      color: red !important;
    `}
  margin-top: 2px !important;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const AlertsContainer = styled.div`
  width: 100%;
  margin-top: 10px;

  h3 {
    font-size: 1.3em;
    font-weight: 600;
    color: #0c5c75;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto; /* Ativa a rolagem apenas na lista */
    background-color: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    height: 200px;

    /* Estilo para os itens da lista */
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #ececec;
      border-radius: 10px;
      padding: 10px;
      margin: 10px 0;
      box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};

      p {
        margin: 5px 0;
        font-weight: 600;
        text-align: center;
      }

      p.date {
        font-weight: 500;
        text-align: start;
      }
    }
  }

  /* Personalização do scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${shade(0.2, '#d9d9d9')};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${shade(0.3, '#d9d9d9')};
  }
`;
