import styled, { keyframes, css } from 'styled-components';

interface IAside {
  isAdmin: boolean;
}
const asideWidth = 100;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-80px)
  }
  to {
    opacity: 1;
    transform: translateX(0)
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-80px)
  }
  to {
    opacity: 1;
    transform: translateX(0)
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px)
  }
  to {
    opacity: 1;
    transform: translate(0px)
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateX(5%)
  }
  to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const Aside = styled.div<IAside>`
  position: fixed;
  left: 0;
  width: ${asideWidth}px;
  background-color: #1b9eb2;
  z-index: 900;

  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${appearFromLeft} 0.6s;

  & ul {
    list-style: none;
    margin-top: 100px;
    & li {
      padding: 20px 0;
      width: 100%;
      & a {
        width: 100%;
      }
      &:hover {
        cursor: pointer;
        svg {
          ${props =>
            props.isAdmin &&
            css`
              color: #ffffff;
            `}
        }
      }
    }
  }

  & svg {
    transition: 0.3s;
    font-size: 2em;
    color: #0c5c75;
    margin: 0 auto;
    display: block;
  }

  & svg.active {
    color: #ffffff;
  }

  & footer {
    position: absolute;
    bottom: 35px;

    & > button {
      background: transparent;
      &:hover {
        cursor: pointer;
        svg {
          color: #ffffff;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    width: 85px;
  }
  @media (max-width: 768px) {
    animation: ${appearFromBottom} 0.6s;
    bottom: 0;
    width: 100%;
    height: 60px;
    flex-direction: row;
    z-index: 999;

    & ul {
      margin-top: 0px;
      display: flex;
      flex: 1;
    }

    & footer {
      position: relative;
      bottom: unset;
      margin: 0 25px;
    }
  }
`;

export const Container = styled.div`
  height: 100dvh;
  right: 0;
  position: absolute;
  width: calc(100% - ${asideWidth}px);
  display: flex;

  & > div.appContent {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 3em 2rem;

    & header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 110px;
      border-bottom: 1px solid #deeef4;
      animation: ${appearFromTop} 0.6s;

      & img.logo {
        display: block;
        width: 6em;
      }

      & > div.headerUser {
        display: flex;
        align-items: center;
        padding-right: 15px;

        & p {
          font-size: 1em;
          color: black;
          margin-right: 15px;
          font-weight: 500;
        }

        & > button.notificationButton {
          background: transparent;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    width: calc(100% - 85px);
    & header {
      height: 93px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    position: initial;
    padding: 0 5px;
    & > div.appContent {
      padding: 0;
    }
  }
  @media (max-width: 500px) {
    padding: 0 0.5em;
  }
`;

export const Content = styled.div`
  height: 100%;
  overflow: hidden;
  padding-top: 2.5rem;
  animation: ${appear} 0.6s;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #d7d7d7;
  }
  ::-webkit-scrollbar-thumb {
    background: #a9a9a9;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 1023px) {
    padding-top: 2rem;
    height: calc(100dvh - 93px);
  }
  @media (max-width: 768px) {
    padding-top: 2rem;
    height: auto;
  }
`;
