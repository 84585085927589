import { ResponsiveLine } from '@nivo/line';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { FaFilter } from 'react-icons/fa';
import { Theme } from '@nivo/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useSelectInfo } from '../../../../../hooks/selectInfo';
import { useToast } from '../../../../../hooks/toast';
import * as S from './styles';
import { loadData } from '../../../../../hooks/loadData';
import { Button, FilterContainer } from './styles';
import User from '../../User';
import DateInput from '../../../../../components/DateInput';
import Input from '../../../../../components/Input';
import { ISensor } from '../../../../../interfaces';
import { Popover } from '@material-ui/core';
import { ptBR } from 'date-fns/locale';

interface ISensorData {
  id: string;
  sensor_id: string;
  value: number;
  timestamp: string;
  created_at: string;
  updated_at: string;
}

export const style: Theme = {
  axis: {
    ticks: { text: { fill: '#0C5C75', font: '"Montserrat", sans-serif', fontSize: '0.6em' } },
  },
  grid: { line: { stroke: '#d1d1d1', strokeWidth: 1, strokeDasharray: '3 3' } },
  tooltip: { container: { fontSize: '0.7em' } },
  legends: { text: { fontSize: '1em' } },
  labels: { text: { fontSize: '2em' } },
};

type LinearGraphProps = {
  selectedSensor?: ISensor;
};

const LinearGraph: React.FC<LinearGraphProps> = ({ selectedSensor }) => {
  const { addToast } = useToast();
  const { selectedSensorId } = useSelectInfo();
  const formRef = useRef<FormHandles>(null);
  const [popUpAnchor, setPopUpAnchor] = useState<Element | null>(null);
  const isPopUpOpen = Boolean(popUpAnchor);
  const [live, setLive] = useState(false);
  const [chartData, setChartData] = useState<ISensorData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentDate = useMemo(() => new Date().toISOString().split('T')[0], []);
  const initialDate = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  }, []);

  const isDesktop = window.innerWidth > 768;
  const maxData = useMemo(() => (isDesktop ? 30 : 15), []);

  const dataFormat = useMemo(() => {
    return chartData.map(data => {
      const date = new Date(data.timestamp);
      return {
        x: format(date, 'dd/MM/y-HH:mm:ss', { locale: ptBR }),
        y: data.value,
      };
    });
  }, [chartData]);

  useEffect(() => {
    const fetchData = () => {
      const max = formRef.current?.getFieldValue('max');
      const initialDate = formRef.current?.getFieldValue('initialDate');
      const finalDate = formRef.current?.getFieldValue('finalDate');

      loadData(setChartData, addToast, selectedSensorId, initialDate, finalDate, max, setIsLoading);
    };

    if (live) {
      fetchData();
      const interval = setInterval(fetchData, 2500);
      return () => clearInterval(interval);
    }
  }, [live, selectedSensorId, addToast]);

  useEffect(() => {
    if (selectedSensorId) {
      handleSubmit();
    }
  }, [selectedSensorId]);

  const handleSubmit = useCallback(async () => {
    const max = formRef.current?.getFieldValue('max');
    const initialDate = formRef.current?.getFieldValue('initialDate');
    const finalDate = formRef.current?.getFieldValue('finalDate');

    await loadData(setChartData, addToast, selectedSensorId, initialDate, finalDate, max, setIsLoading);
    handleClosePopUp();
  }, [selectedSensorId, addToast]);

  const handleChangeLiveMode = () => setLive(prevLive => !prevLive);

  const handleClosePopUp = () => setPopUpAnchor(null);

  const graph = useMemo(() => {
    return (
      <ResponsiveLine
        data={[{ id: User.name, data: dataFormat }]}
        margin={{ top: 25, right: 45, bottom: 45, left: 50 }}
        axisTop={null}
        axisRight={null}
        curve="linear"
        lineWidth={2}
        theme={style}
        yScale={{
          type: 'linear',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        axisBottom={{
          orient: 'left',
          tickSize: 0,
          legendOffset: 40,
          legendPosition: 'middle',
          tickRotation: 75,
        }}
        axisLeft={{
          orient: 'left',
          tickPadding: 12,
          legendOffset: -40,
          tickSize: 0,
          legendPosition: 'middle',
          legend: selectedSensor?.unit_of_measurement,
        }}
        markers={[
          {
            axis: 'y',
            value: 0,
            lineStyle: { stroke: '#0C5C75', strokeWidth: 1 },
          },
          {
            axis: 'x',
            value: 0,
            lineStyle: { stroke: '#0C5C75', strokeWidth: 1 },
          },
        ]}
        colors={['#FFB30D', '#00CFEF', '#0C5C75', '#e3eff4']}
        enableSlices={false}
        areaOpacity={0.2}
        useMesh
        motionStiffness={100}
        enableGridY={false}
        enableGridX
        enablePoints
        yFormat=">-.3f"
      />
    );
  }, [dataFormat, selectedSensor]);

  return (
    <S.Container>
      <S.Content>
        <S.GrafContainer>{graph}</S.GrafContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Popover
            open={isPopUpOpen}
            anchorEl={popUpAnchor}
            onClose={handleClosePopUp}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <S.FiltersPopUp>
              <strong>Filtros</strong>
              <section>
                <div>
                  <h3>Máximo:</h3>
                  <Input containerClassName="max-input" name="max" type="number" defaultValue={formRef.current?.getFieldValue('max')} />
                </div>
                <div className="date-input">
                  <h3>Inicial:</h3>
                  <DateInput name="initialDate" type="date" defaultValue={formRef.current?.getFieldValue('initialDate')} />
                </div>
                <div className="date-input">
                  <h3>Final:</h3>
                  <DateInput name="finalDate" type="date" defaultValue={formRef.current?.getFieldValue('finalDate')} />
                </div>
                <Button onClick={handleSubmit} className="sendMessageButton">
                  Aplicar
                </Button>
              </section>
            </S.FiltersPopUp>
          </Popover>
          <div className="mobile-row">
            <S.LiveButton type="button" live={live} onClick={handleChangeLiveMode}>
              <div className="live-icon" />
              Ao vivo
            </S.LiveButton>
            <Button className="mobile-filters-button" onClick={event => setPopUpAnchor(event.currentTarget)} type="button">
              <FaFilter size="1em" color="#fff" />
              Filtros
            </Button>
          </div>
          <FilterContainer>
            {isLoading && <S.LoadingSpinner size={20} />}
            <div className="filterLegends">
              <section>
                <S.LiveButton type="button" live={live} onClick={handleChangeLiveMode}>
                  <div className="live-icon" />
                  Ao vivo
                </S.LiveButton>
                <div>
                  <h3>Máximo:</h3>
                  <Input containerClassName="max-input" name="max" type="number" defaultValue={maxData} />
                </div>
                <div>
                  <h3>Inicial:</h3>
                  <DateInput name="initialDate" type="date" defaultValue={initialDate} />
                </div>
                <div>
                  <h3>Final:</h3>
                  <DateInput name="finalDate" type="date" defaultValue={currentDate} />
                </div>
                <Button onClick={handleSubmit} className="sendMessageButton">
                  <FaFilter size="1.5em" color="#fff" />
                </Button>
              </section>
            </div>
          </FilterContainer>
        </Form>
      </S.Content>
    </S.Container>
  );
};

export default LinearGraph;
